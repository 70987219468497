export function mergeObjects(baseConfig, targetConfigUSA, targetConfigCanada, countryCode) {
  baseConfig.countryCode = countryCode;
  if (countryCode === 'CAN') {
    baseConfig = {...baseConfig, ...targetConfigCanada};
  }
  if (countryCode === 'USA') {
    baseConfig = {...baseConfig, ...targetConfigUSA};
  }

  return baseConfig;
}

export function getBasePath(qualifyBasePath, prequalifyBasePath, authorPath) {
  if (window.location.href.includes(authorPath)) {
    return '';
  }
  const pathBaseName = window.location.pathname.includes(qualifyBasePath) ? qualifyBasePath : prequalifyBasePath;
  window.pathBaseName = pathBaseName;
  return pathBaseName;
}
