import {localEnvironment} from '../../config/localEnvironment';
import {devEnvironment} from '../../config/devEnvironment';
import {qaEnvironment} from '../../config/qaEnvironment';
import {prodEnvironment} from "../../config/prodEnvironment";

const loadConfigFromSource = (source) => {
  const sourceEnv = source.split("-")[1];
  if (sourceEnv === "Q") {
    return devEnvironment('USA');
  }
  if (sourceEnv === "U") {
    return qaEnvironment('USA');
  }
  if (sourceEnv === "P") {
    return prodEnvironment('USA', true);
  }
  return localEnvironment('USA');
};
export default loadConfigFromSource;
