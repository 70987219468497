import {getBasePath, mergeObjects} from './commonUtil';

export function devEnvironment(countryCode) {
  let baseConfig = {
    exrEnv: 'dev',
    exrServiceApi: 'https://api-dev.int.fordcredit.com/',
    adobeSrc: 'https://assets.adobedtm.com/248d63c2ff6b/01cce4ecbac0/launch-EN3068e51f4ce14dacac576c5214f9e027-development.min.js',
    mouseflowKey: '9fdf02dc-241d-4f2d-833b-2216e458dddf',

    // OPTIMIZELY VALUES
    optimizelyKey: 'SCzEuGCuiJyjsj3BPPraW',
    experimentVariation: 'exp_id_-_93',

    // TIMEOUT VALUES
    warningTimeMilliseconds: '120000',
    sessionEndTimeMilliseconds: '420000',

    // PREPOPULATE FORM FIELDS
    prepopulateApprovedCustomerTestDataUSA: false,
    prepopulateCreditFreezeCustomerTestDataUSA: false,
    prepopulateApprovedCustomerTestDataCAN: false,
    prepopulateCreditFreezeCustomerTestDataCAN: false,
    prepopulateQuebecCreditFreezeCustomerTestDataCAN: false,

    // WEB COMPONENT VALUES
    appModalURL: 'https://wwwdev.postprequalify.ford.com/webcompbutton/prequal-app-modal.js',
    minifiedCSSURL: 'https://wwwdev.postprequalify.ford.com/webcompbutton/prequal-app.css',

    // TARGET URLS
    specialEventTarget: 'https://www.buyfordnow.com/salesevent',

    // FLAGS
    specialEventFlag: true,
    offersAndIncentivesFlag: true,
    aemReviewOfferPageFlag: true,
    aemRejectedPageFlag: true,
    aemConnectWithDealer: true,
    aemDealerSubmission: true,
    aemDealerConfirmation: true,
    aemTimeOutSessionFlag: true,
    aemTermsAndConditionFlag: true,
    useEnterpriseReCaptcha: false,

    // AEM VALUES
    AEM_PUBLISHER_URL: 'https://wwwdev.creditbrandlb.ford.com',
    AEM_AUTHOR_URL: 'https://wwwdev.creditbrandauthorlb.ford.com',
    basePath: getBasePath('/finance/qualify', '/finance/prequalify', 'https://wwwdev.creditbrandauthorlb.ford.com')
  };
  const targetConfigUSA = {
    exploreVehiclesTarget: 'https://shop.ford.com/showroom/',
    prequalifyTarget: 'https://wwwdev.postprequalify.ford.com/finance/prequalify',
    shopTarget: 'https://shop.ford.com/showroom/',
    shopIncentivesTarget: 'https://shop.ford.com/showroom/?linktype=incentives#/',
    ocaTarget: 'https://wwwqa.ford.com/finance/apply/',
    countryCode: 'USA',
    AEM_PAGE_PATH: '/content/ucl/prequal/ford/en-us/tiles/landingpage'
  };
  const targetConfigCanada = {
    exploreVehiclesTarget: 'https://shop.ford.ca/showroom/',
    prequalifyTarget: 'https://wwwdev.postprequalifyca.ford.com/finance/prequalify',
    shopTarget: 'https://shop.ford.ca/showroom/',
    shopIncentivesTarget: 'https://shop.ford.ca/showroom/?linktype=incentives#/',
    ocaTarget: 'https://wwwqa.ford.ca/finance/apply/',
    countryCode: 'CAN',
    AEM_PAGE_PATH: '/content/ucl/prequal/ford/en-ca/tiles/landingpage'
  };

  baseConfig = mergeObjects(baseConfig, targetConfigUSA, targetConfigCanada, countryCode);
  return baseConfig;
}
