import FordCreditWebCompLogo from "./assets/images/get-prequalified-button.webp";
import LoadingSpinner from "./assets/icons/progress-indicator.png";
import FordCreditLogo from "./assets/icons/ford-credit-logo.png";
import CloseIcon from "./assets/icons/close-black.svg";

import loadConfigFromSource from "./utils/configLoader/LoadConfigFromSource";
import {v4 as randomUUID} from 'uuid';

export function doesElementHaveChildren(element) {
  return element.innerHTML.trim().length >= 1;
}
const innerLoadingModalHTML = `<style>
    .modal {
      display: none; 
      position: fixed; 
      z-index: 10100; 
      padding-top: 100px; 
      left: 0;
      top: 0;
      width: 100%; 
      height: 100%; 
      overflow: auto; 
      background-color: rgb(0,0,0); 
      background-color: rgba(0,0,0,0.4);
    }


.modal-content {
  background-color: #fefefe;
  border: 1px solid #888;
  max-width: 430px;
  height: 508px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 10px 84px 10px;
  margin: -4px auto auto;
}

.centered-content {
  display: flex;
  margin: auto;
  align-items: center;
  text-align: center;
}

.loading-modal-header {
    display: flex;
    padding:15px;
    justify-content: space-between;
    flex-direction: row;
}
.loading-modal-header button {
    border: 0;
    background: #fff;
    margin-top: -15px;
    cursor: pointer;
}
.preqaul--web--rotating-spinner {
    animation-name: spin;
    animation-duration: 5000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.prequal--wc--ford-credit-logo-dealer-host {
    width: 151px;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

</style>

<div id="prequalLoadingModal" class="modal">
  <div class="modal-content" id="modal-content">
    <div class="loading-modal-header">
        <div>
            <img class="prequal--wc--ford-credit-logo-dealer-host" name="ford-credit-logo"  src='${FordCreditLogo}' alt="Ford Credit Logo">
        </div>
        <button type="button" onclick="hideModal()">
            <img id="modal-close-icon" src='${CloseIcon}' alt="close">
        </button>
    </div>
    <div class="centered-content">
        <p ><img width='80px' class='preqaul--web--rotating-spinner' src='${LoadingSpinner}' alt='loading..' /></p>
    </div>
  </div>
</div>`;

function createButton(id){
  const clickEvt=`"setShowDialog(event,\'${id}\')"`;
  const parent = document.createElement('div');

  parent.innerHTML = `
<img id='wc-prequal-btn' alt="Ford Motor Credit Company Click to Get Prequalified" title="Ford Motor Credit Company Click to Get Prequalified" style='max-width:100%; max-height:90px;height:auto;cursor: pointer;' src='${FordCreditWebCompLogo}' onclick=${clickEvt}/> 
`;
  const hostElement = document.createElement('prequal-loading-modal');
  document.body.appendChild(hostElement);
  const shadowRoot = hostElement.attachShadow({mode: 'open'});
  shadowRoot.innerHTML += innerLoadingModalHTML;
  return parent;
}
function createTextButton(id){
  const clickEvt=`setShowDialog(event,\'${id}\')`;
  const parent = document.createElement('div');
  parent.innerHTML = `<a id='prequal--get-prequalified-link' onclick=${clickEvt}> Get Prequalified </a>`;
  const hostElement = document.createElement('prequal-loading-modal');
  document.body.appendChild(hostElement);
  const shadowRoot = hostElement.attachShadow({mode: 'open'});
  shadowRoot.innerHTML += innerLoadingModalHTML;
  return parent;
}
function setShowDialogScript(appModalURL){

  const showDialogScript = document.createElement('script')
  let modalTag = 'divElement.innerHTML = `<prequal-app-modal modalId= \"${id}\"></prequal-app-modal>` \n ';
  let showDialogScriptFunction = 'function hideModal(){' +
      'document.querySelector(\'prequal-loading-modal\').shadowRoot.getElementById(\'prequalLoadingModal\').style.display = \'none\';\n' +
      '}' +
      'function setShowDialog(event, id) {\n' +
      'var modalContent = document.querySelector(\'prequal-app-button\').shadowRoot.getElementById(\'modal-content\');\n' +
      'var modal = document.querySelector(\'prequal-loading-modal\').shadowRoot.getElementById(\'prequalLoadingModal\');\n' +
      'modal.addEventListener(\'click\',function(event){if(modal && !modalContent.contains(event.target)) modal.style.display = \'none\';}) \n' +
      '  const elem = document.getElementById(id);  \n' +
      '  const source = elem.getAttribute(\'prequalSource\') ?? elem.getAttribute(\'prequal-source\'); \n' +
      '  const inputs = elem.getAttribute(\'prequalInput\') ?? elem.getAttribute(\'prequal-input\'); \n' +
      '    const openEvent =  new CustomEvent(\'prequalModalOpenEvent\', {\n' +
      '      detail: {prequalSource: source, prequalInput: inputs},\n' +
      '      bubbles: true,\n' +
      '      cancelable: true,\n' +
      '      composed: true,\n' +
      '    });\n' +
      '    const ecomSubmissionEvent =  new CustomEvent(\'prequalSubmissionResultEvent\', {\n' +
      '      detail: {status : null,prequalifiedAmount : null,expirationDate : null}'+
      '});\n' +
      'const loadScript = () => {\n' +
      '  return new Promise((resolve, reject) =>{\n' +
      '    try {\n' +
      '\n' +
      '      if(document.querySelector(\'#prequal--id\') == null) {\n' +
      '        const webCompScript = document.createElement(\'script\');\n' +
      '        webCompScript.src = \''+appModalURL+'\'\n' +
      '        webCompScript.setAttribute("async", true);\n' +
      'document.querySelector(\'prequal-loading-modal\').shadowRoot.getElementById(\'prequalLoadingModal\').style.display = \'block\';\n' +
      '        webCompScript.addEventListener(\'load\',(ev) => {\n' +
      '          resolve({status: true});\n' +
      '        })\n' +
      '        webCompScript.addEventListener(\'error\',(ev) => {\n' +
      '          reject({status: false,message: \'error loading script\'});\n' +
      '        })\n' +
      '        document.head.append(webCompScript);\n' +
      '\n' +
      '      } else {\n' +
      '        document.dispatchEvent(openEvent);\n' +
      '        document.dispatchEvent(ecomSubmissionEvent);\n' +
      '      }\n' +
      '    } catch(e){\n' +
      '      reject(e)\n' +
      '    }\n' +
      '  })\n' +
      '}\n' +
      'loadScript().then(() => {\n' +
      '        const divElement = document.createElement(\'div\');\n' +
      '        divElement.setAttribute(\'id\', \'prequal--id\')\n' +
      modalTag +
      '        document.body.prepend(divElement)\n'+
      '})'+
      '  }'
  showDialogScript.innerHTML = showDialogScriptFunction;
  return showDialogScript;
}

export class PreQualWebComponentButton extends HTMLElement {
  root;
  prequalSource;
  prequalInput;
  id;

  constructor() {
    super();
    this.attachShadow({mode: 'open'});
  }

  connectedCallback() {
    this.prequalSource = this.getAttribute('prequalSource') ?? this.getAttribute('prequal-source');
    this.prequalInput = this.getAttribute('prequalInput') ?? this.getAttribute('prequal-input');
    this.setAttribute("id", randomUUID());
    this.id = this.getAttribute("id");
    const webComponentShadow = this.shadowRoot;
    const appConfig = loadConfigFromSource(this.prequalSource);
    if (!doesElementHaveChildren(webComponentShadow)) {
      this.mountComponent(appConfig);
    }
  }

  mountComponent(appConfig) {
    const reactDiv = document.createElement('div');
    this.shadowRoot.appendChild(setShowDialogScript(appConfig.appModalURL));
    this.shadowRoot.appendChild((this.getButtonStyle() != null && this.getButtonStyle().toUpperCase() === 'TEXT') ? createTextButton(this.id) : createButton(this.id));
    this.shadowRoot.appendChild(reactDiv);
  }

  getButtonStyle(){
    const object = {};
    this.prequalInput.split('^').forEach((data)=>{
      let keyValue = data.split('~');
      object[keyValue[0]] = keyValue[1];
    });

    return object.buttonStyle;
  }

}
customElements.define('prequal-app-button', PreQualWebComponentButton);
